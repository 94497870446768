export const followUpQuotes = (dateRange, quotes) => {
    let groupByDate = {}
    let res = {
        unique: {},
        quantity: [],
        totals: [],
        accumulatedValuesOfNewQuotesSent: 0,
        accumulatedValuesOfModifiedQuotesSent: 0,
    }
    dateRange.forEach(date => {
        groupByDate[date] = [0, 0]
        res.unique[date] = ''
        quotes.forEach(quote => {
            if (quote.date.value.substring(0, 10) === date) {
                if (
                    !res.unique[date].includes(quote.id) &&
                    quote.number &&
                    quote.number.toString().includes('.01')
                ) {
                    if (res.unique[date] === '') {
                        res.unique[date] = `${quote.id}`
                    } else {
                        res.unique[date] += ` ${quote.id}`
                    }
                    groupByDate[date][1] += quote.calculatedTotal
                    res.accumulatedValuesOfNewQuotesSent +=
                        quote.calculatedTotal
                } else if (quote.number) {
                    groupByDate[date][0] += 1
                    if (!res.unique[date].includes(quote.id)) {
                        groupByDate[date][1] += quote.calculatedTotal
                        res.accumulatedValuesOfModifiedQuotesSent +=
                            quote.calculatedTotal
                    }
                }
            }
        })
        res.quantity.push(groupByDate[date][0])
        res.totals.push(groupByDate[date][1])
    })
    res.unique = Object.values(res.unique).map(item => {
        return item === '' ? 0 : item.split(' ').length
    })
    return res
}

export const latestVersionOfQuote = quotes => {
    const res = {}
    quotes.forEach(quote => {
        res[quote.id] = quote
    })
    return Object.values(res)
}

export const acumulativeValue = arr => {
    return arr.reduce((acumulator, item) => acumulator + item, 0)
}
